/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {graphql, useStaticQuery} from "gatsby";

const Footer = () => {
   const data = useStaticQuery(graphql`
       query {
            site{
                siteMetadata {
                    author
                }
            }
        }
   `)

   return (
    <footer className="footer mt-auto text-muted py-5">
        <div className="container">
            <p className="float-end mb-1">
                <a href="#">Nahoru</a>
            </p>
            <p className="mb-1">{data.site.siteMetadata.author} © {new Date().getFullYear()}</p>
        </div>
    </footer>
   );
}

export default Footer
