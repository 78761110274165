import React from "react";
import logoImg from "../../assets/custom-style/images/petr-balvin-top2.webp";



export default function Logo() {
    return (
        <div className={"d-flex align-items-center"}>
            <img className={"brand-img shadow me-3"} src={logoImg}/>
            <div className={"brand-name"}>
                Petr Balvín
                <span>Specialista na weby < /span>
            </div>
        </div>


    )
        ;
}
