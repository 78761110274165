import React from "react";
import {Link} from "gatsby";
import Logo from "../Logo/logo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";

const TopMenu = () => (
    <nav className="navbar navbar-expand-md navbar-light bg-white fixed-top">
        <div className="container">
            <Link to={"/"} className="navbar-brand text-primary">
                <Logo/>

            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                    <li className="nav-item">
                        <Link to='/' className="nav-link" activeClassName="active">Úvod</Link>
                    </li>

                    {/* <li className="nav-item">
                        <Link to='/' className="nav-link" activeClassName="active">Cena</Link>
                    </li>*/}
                    <li className="nav-item me-md-4">
                        <Link to='/kontakt' className="nav-link" activeClassName="active">Kontakt</Link>
                    </li>

                    <li className="nav-item">
                        <Link to='https://www.facebook.com/petr.balvin.3'
                              target={"_blank"} className="nav-link text-muted" activeClassName="active">
                            <FontAwesomeIcon icon={faFacebook} />
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='https://www.facebook.com/petr.balvin.3'
                              target={"_blank"} className="nav-link text-muted" activeClassName="active">
                            <FontAwesomeIcon icon={faInstagram} />
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='https://www.facebook.com/petr.balvin.3'
                              target={"_blank"} className="nav-link text-muted" activeClassName="active">
                            <FontAwesomeIcon icon={faPhone} />
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='https://www.facebook.com/petr.balvin.3'
                              target={"_blank"} className="nav-link text-muted" activeClassName="active">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Link>
                    </li>

                </ul>

            </div>
        </div>
    </nav>

);

export default TopMenu

